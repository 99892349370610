<template>
	<main>
	
	</main>
</template>

<script>
export default {
	name: "diff"
}
</script>

<style scoped>

</style>